<template>
    <div id="page-provider">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <router-link class="btn btn-back-to" :to="back_to">Back to System Admin</router-link>

                    <h1 class="heading">Service Provider Details</h1>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="config" ref="config">
                        <app-loader v-if="loading.info || processing" fixed-on="desktop"></app-loader>

                        <div class="board settings">
                            <app-error :value="error_message"></app-error>

                            <div class="info name">
                                <app-input v-model="provider.Name"     label="Name" :error="errors.fields.Name" @change="showNextError('Name')" :disabled="processing"></app-input>
                                <app-input  :value="provider.SPID"     label="SPID"                                                            disabled></app-input>
                            </div>

                            <div class="info name">
                                <app-input v-model="provider.DefaultSupportPhoneNumber" label="Support Phone Number" :error="errors.fields.DefaultSupportPhoneNumber" @change="showNextError('DefaultSupportPhoneNumber')" :disabled="processing"></app-input>
                                <app-input v-model="provider.TaxNumber"                 label="Tax Number" :error="errors.fields.TaxNumber" @change="showNextError('TaxNumber')" :disabled="processing"></app-input>
                            </div>

                            <h5>Contact Person</h5>
                            <div class="info">
                                <app-input
                                    v-model="provider.ContactPersonFirstName"

                                    label="First Name"

                                    :error="errors.fields.ContactPersonFirstName"
                                    :disabled="processing"

                                    @change="showNextError('ContactPersonFirstName')"
                                />

                                <app-input
                                    v-model="provider.ContactPersonLastName"

                                    label="Last Name"

                                    :error="errors.fields.ContactPersonLastName"
                                    :disabled="processing"

                                    @change="showNextError('ContactPersonLastName')"
                                />
                            </div>

                            <h5>Notifications Settings</h5>
                            <div class="info notifications">
                                <app-input v-model="provider.DefaultEmailFromName"     label="Default Email from Name" :error="errors.fields.DefaultEmailFromName" @change="showNextError('DefaultEmailFromName')" :disabled="processing"></app-input>
                                <app-input v-model="provider.DefaultEmailFromAddress"  label="Default Email from Address" :error="errors.fields.DefaultEmailFromAddress" @change="showNextError('DefaultEmailFromAddress')" :disabled="processing"></app-input>
                            </div>
                            <div class="info notifications">
                                <app-input v-model="provider.DefaultSMSFromName"       label="Default SMS from Name" :error="errors.fields.DefaultSMSFromName" @change="showNextError('DefaultSMSFromName')" :disabled="processing"></app-input>
                                <app-input v-model="provider.DefaultSMSFromNumber"     label="Default SMS from Number" :error="errors.fields.DefaultSMSFromNumber" @change="showNextError('DefaultSMSFromNumber')" :disabled="processing"></app-input>
                            </div>

                            <h5>Logos</h5>
                            <div class="info logo">
                                <app-input v-model="provider.LogoWideURL" label="Wide Logo URL"       :maxlength="null" :error="errors.fields.LogoWideURL" @change="showNextError('LogoWideURL')"></app-input>

                                <div class="logo-preview" v-if="loaded_images.LogoWideURL && !errors.logos.LogoWideURL">
                                    <img class="wide" :src="original.LogoWideURL">
                                </div>
                            </div>
                            <div class="info logo">
                                <app-input v-model="provider.LogoIconURL" label="Small/Icon Logo URL" :maxlength="null" :error="errors.fields.LogoIconURL" @change="showNextError('LogoIconURL')"></app-input>

                                <div class="logo-preview" v-if="loaded_images.LogoIconURL && !errors.logos.LogoIconURL">
                                    <img class="small" :src="original.LogoIconURL">
                                </div>
                            </div>

                            <router-link class="btn btn-primary btn-small" :to="{ name: 'add-member' }">Add a member</router-link>
                        </div>

                        <div class="board features">
                            <app-checkbox-group
                                v-model="provider.Features"
                                :options="all_available_modules"

                                label="Features"

                                key-value="Name"
                                key-title="Title"

                                key-readonly="immutable"
                                key-disabled="immutable"

                                :disabled="processing"
                            />
                        </div>

                        <div class="board branding">
                            <h5>Branding preferences</h5>

                            <div class="branding-body" :class="{ loading: loading.branding }">
                                <app-loader                 v-if="loading.branding"></app-loader>
                                <app-error             v-else-if="errors.branding" v-model="errors.branding"></app-error>
                                <app-provider-branding v-else-if="with_branding"   v-model="provider_branding" :original="original_branding" :disabled="processing"></app-provider-branding>
                            </div>
                        </div>

                        <button class="btn btn-primary btn-small" @click="save" :disabled="!is_changed || processing">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'

import appInput from '@/components/app-input'
import appCheckboxGroup from '@/components/app-checkbox-group'

import appProviderBranding from '@/components/app-provider-branding'

import ServiceProviderValidator from '@/validators/service-provider-validator'
import errMessage from '@/helpers/errMessage'

export default {
    props: {
        search: { type: Object, default: () => ({ word: '', option: null })}
    },
    
    components: {
        appLoader,
        appError,

        appInput,
        appCheckboxGroup,

        appProviderBranding,
    },

    data() {
        return {
            provider: {
                Name: '',

                Features: [],

                ContactPersonFirstName: '',
                ContactPersonLastName: '',
            },
            provider_branding: {},

            original: {
                Name: '',

                Features: [],

                ContactPersonFirstName: '',
                ContactPersonLastName: '',
            },
            original_branding: {},

            errors: {
                settings: null,
                fields: {},
                branding: null,

                fields: {},
                logos: {},
            },

            loading: {
                info: false,
                branding: false,
            },

            loaded_images: {},

            processing: false,

            referrer: null,
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.loadProviderInfo()
        },
        
        loadProviderInfo() {
            this.loading.info = true
            
            if (!this.providers_features || this.providers_features.length == 0) {
                this.$store.dispatch('getProvidersFeatures')
            }

            this.$store.dispatch('api_serviceprovider/getCachedServiceProviderByUUID', { UUID: this.uuid }).then(provider => {
                this.initProviderInfo(provider)
                this.loadBranding()

                this.loading.info = false
            }).catch(error => {
                console.log('Error api_serviceprovider/getCachedServiceProviderByUUID', this.uuid)
                console.error(error)

                this.$router.push({ name: 'providers', replace: true })
            })
        },
        initProviderInfo(provider) {
            this.provider = {
                ...this.provider,
                ...provider,
            }

            this.original = {
                ...this.provider,
                ...provider,
            }

            this.checkLogoAvailability('LogoWideURL')
            this.checkLogoAvailability('LogoIconURL')
        },

        checkLogoAvailability(key) {
            const sizes = {
                LogoWideURL: 'wide',
                LogoIconURL: 'small'
            }

            const img = new Image()

            img.onload = () => {
                this.errors.logos = {
                    ...this.errors.logos,
                    [key]: null,
                }

                this.loaded_images = {
                    ...this.loaded_images,
                    [key]: true,
                }
            }

            img.onerror = () => {
                this.errors.logos = {
                    ...this.errors.logos,
                    [key]: `Something went wrong with the ${ sizes[key] } logo loading`,
                }

                this.loaded_images = {
                    ...this.loaded_images,
                    [key]: true,
                }
            }

            img.src = this.original[key]
        },

        loadBranding() {
            this.loading.branding = true
            
            this.$store.dispatch('getBrandingBySPID', { spid: this.provider.SPID }).then(branding => {
                this.initBranding(branding)

                this.loading.branding = false
            }).catch(error => {
                this.errors.branding = errMessage(error)

                this.loading.branding = false
            })
        },
        initBranding(branding) {
            let provider_branding = {}
            let original_branding = {}

            for (const key in branding) {
                provider_branding[ key ] = [...branding[ key ]]
                original_branding[ key ] = [...branding[ key ]]
            }

            this.provider_branding = provider_branding
            this.original_branding = original_branding
        },

        save() {
            if (this.is_changed) {
                const scrollToError = () => { window.scrollTo(0, this.$refs.config.offsetTop) }

                const showSuccessMessage = () => {
                    this.$store.dispatch('addToast', {
                        message: 'Changes was successfully applied',
                        type: 'success',
                        delay: 5000,
                    })
                }

                if (this.is_changed_info || this.is_changed_logo) {
                    if (this.validation()) {
                        this.saveProvider()
                            .then(() => {
                                this.saveBranding()
                                    .then(showSuccessMessage)
                                    .catch(scrollToError)
                            })
                            .catch(scrollToError)
                    } else {
                        window.scrollTo(0, this.$refs.config.offsetTop)
                    }
                } else {
                    this.saveBranding()
                        .then(showSuccessMessage)
                        .catch(scrollToError)
                }
            }
        },

        saveProvider() {
            this.processing = true

            return this.$store.dispatch('api_serviceprovider/UpdateServiceProvider', { ...this.provider })
                .then(provider => {
                    this.initProviderInfo(provider)

                    this.processing = false
                    this.$store.dispatch('RefreshServiceProviders')
                })
                .catch(error => {
                    if (error && error.response && error.response.data && error.response.data.err_field) {
                        console.log('field error', error.response.data.err_field)
                        this.errors.fields[error.response.data.err_field] = errMessage(error)
                    } else {
                        this.errors.settings = errMessage(error)
                    }
                    this.processing = false

                    return Promise.reject(error)
                })
        },

        saveBranding() {
            this.processing = true

            return this.$store.dispatch('saveBranding', { spid: this.provider.SPID, scheme: this.provider_branding })
                .then(branding => {
                    this.initBranding(branding)
                    
                    if (this.provider.SPID == this.current_spid) {
                        this.$emit('apply-branding', branding)
                    }

                    this.processing = false
                })
                .catch(error => {
                    this.errors.branding = errMessage(error)

                    this.processing = false

                    return Promise.reject(error)
                })
        },

        validation() {
            let is_valid = true

            this.errors.fields = {}

            const values = {
                Name: {
                    value: this.provider.Name,
                    message: 'Please, enter valid provider name',
                },

                DefaultEmailFromName: {
                    value: this.provider.DefaultEmailFromName,
                    message: 'Please, enter valid name',
                },

                DefaultEmailFromAddress: {
                    value: this.provider.DefaultEmailFromAddress,
                    message: 'Please, enter valid email address',
                },

                DefaultSMSFromName: {
                    value: this.provider.DefaultSMSFromName,
                    message: 'Please, enter valid name',
                },

                DefaultSMSFromNumber: {
                    value: this.provider.DefaultSMSFromNumber,
                    message: 'Please, enter valid mobile number',
                },

                LogoWideURL: {
                    value: this.provider.LogoWideURL,
                    message: 'Please, enter valid wide logo URL',
                },

                LogoIconURL: {
                    value: this.provider.LogoIconURL,
                    message: 'Please, enter valid small logo URL',
                },

                ContactPersonFirstName: {
                    value: this.provider.ContactPersonFirstName,
                    message: 'Please, enter valid name',
                },
                ContactPersonLastName: {
                    value: this.provider.ContactPersonLastName,
                    message: 'Please, enter valid surname',
                },
            }

            for (const key in values) {
                if (ServiceProviderValidator.isRuleExists(key)) {
                    if (ServiceProviderValidator.isInvalid(key, values[key].value, values[key].message)) {
                        this.errors.fields[ key ] = ServiceProviderValidator.getError()
                        is_valid = false
                    }
                }
            }

            return is_valid
        },

        showNextError(except) {
            if (except) {
                delete this.errors.fields[ except ]
            }
        },

        isArrayChanged(value, original, is_color) {
            let is_changed = false

            const length = value.length

            if (length == original.length) {
                if (is_color) {
                    for (let i = 0; i < length; i++) {
                        if (this.hexColor(value[i]) != this.hexColor(original[i])) {
                            is_changed = true
                            break
                        }
                    }
                } else {
                    for (let i = 0; i < length; i++) {
                        if (original.indexOf( value[i] ) < 0) {
                            is_changed = true
                            break
                        }
                    }
                }
            } else {
                is_changed = true
            }

            return is_changed
        },

        hexColor(color) {
            let hex = color ? color.replace('#', '').trim().toLowerCase() : '00000000'

            if (hex.length == 3) {
                let hex_6 = ''

                for (let i = 0; i < 3; i++) {
                    hex_6+= hex[i] + hex[i]
                }

                hex = hex_6
            }

            return hex
        },
    },

    computed: {
        ...mapGetters([
            'modules_user',
            'modules_env',
            'providers_features',

            'current_spid',

            'module_types',
        ]),

        back_to() {
            return this.referrer && this.referrer.name == 'providers'
                ? this.referrer
                : { name: 'providers' }
        },

        error_message() {
            return this.errors.settings || this.errors.logos.LogoWideURL || this.errors.logos.LogoIconURL
        },

        uuid() {
            return this.$route.params.uuid
        },

        with_branding() {
            return Object.keys(this.provider_branding).length > 0
        },

        all_available_modules() {
            const new_modules = {}

            const module_names = Object.keys(this.modules_user)

            for (const i in module_names) {
                new_modules[module_names[i]] =  {
                    ...this.modules_user[module_names[i]]
                }

                const type = new_modules[module_names[i]].Type

                if (type == this.module_types.PRODUCT || type == this.module_types.SERVICE || type == this.module_types.TOOL) {
                    new_modules[module_names[i]].immutable = false
                } else {
                    new_modules[module_names[i]].immutable = true
                }
            }

            return new_modules
        },

        is_changed() {
            return this.is_changed_info || this.is_changed_logo || this.is_changed_branding
        },

        is_changed_info() {
            return this.is_changed_contact_person ||
                this.is_changed_name ||
                this.is_changed_features ||
                this.is_changed_notification_defaults
        },

        is_changed_contact_person() {
            return this.provider.ContactPersonFirstName != this.original.ContactPersonFirstName ||
                this.provider.ContactPersonLastName != this.original.ContactPersonLastName
        },
        is_changed_name() {
            return this.provider.Name != this.original.Name
        },
        is_changed_features() {
            return this.isArrayChanged(this.provider.Features, this.original.Features)
        },
        is_changed_notification_defaults() {
            return this.provider.DefaultEmailFromName != this.original.DefaultEmailFromName ||
                this.provider.DefaultEmailFromAddress != this.original.DefaultEmailFromAddress ||
                this.provider.DefaultSMSFromName != this.original.DefaultSMSFromName ||
                this.provider.DefaultSMSFromNumber != this.original.DefaultSMSFromNumber ||
                this.provider.DefaultSupportPhoneNumber != this.original.DefaultSupportPhoneNumber ||
                this.provider.TaxNumber != this.original.TaxNumber
        },

        is_changed_logo() {
            return this.provider.LogoWideURL != this.original.LogoWideURL || this.provider.LogoIconURL != this.original.LogoIconURL
        },

        is_changed_branding() {
            let is_changed = false

            for (const key in this.provider_branding) {
                if (this.isArrayChanged(this.provider_branding[ key ], this.original_branding[ key ], true)) {
                    is_changed = true
                    break
                }
            }

            return is_changed
        },
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.referrer = from
        })
    },
}
</script>

<style lang="scss">
#page-provider {
    margin-top: 24px;
    padding-bottom: 80px;

    .btn-back-to {
        margin-bottom: 12px;
    }

    .config {
        margin: 40px 0 0;
        padding: 24px;
        border-radius: $border-radius-secondary;
        background: var(--color-component-bg-primary);
        box-shadow: var(--box-shadow-primary);
        position: relative;

        .app-loader {
            border-radius: $border-radius-secondary;
        }

        .app-error {
            margin-bottom: 24px;
        }

        .board {
            margin-top: 40px;

            &:first-child {
                margin: 0;
            }

            &>h5 {
                font-size: 20px;
                line-height: 22px;
                margin-bottom: 24px;
            }
        }

        .settings {
            .info {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                margin-bottom: 30px;

                .app-input {
                    width: calc(50% - 15px);
                    max-width: 370px;
                    margin-right: 30px;

                    &:last-child {
                        margin: 0;
                    }
                }

                .logo-preview {
                    display: flex;
                    align-items: center;

                    max-width: calc(50% - 15px);

                    height: 48px;
                    padding: 8px;

                    border-radius: $border-radius-primary;
                    border: solid 1px var(--color-input-border);

                    img {
                        display: block;

                        &.wide {
                            max-width: 100%;
                            height: auto;
                            max-height: 32px;
                            object-fit: contain;
                        }

                        &.small {
                            width: 100%;
                            max-width: 32px;

                            height: auto;
                            max-height: 32px;
                        }
                    }
                }
            }
        }

        .features {
            .app-checkbox-group {
                .options {
                    justify-content: flex-start;

                    .app-checkbox {
                        margin: 24px 48px 0 0;
                        white-space: nowrap;

                        &:last-child { margin-right: 0; }
                    }
                }
            }
        }

        .branding {
            .branding-body {
                position: relative;

                &.loading {
                    min-height: 120px;
                    border-radius: $border-radius-secondary;
                }

                .app-loader {
                    background: var(--color-table-head-bg);

                    svg {
                        width: 48px;
                        height: 48px;
                    }
                }
            }
        }

        &>.btn {
            margin-top: 30px;
        }
    }
}

@media (max-width: $tablet-size) {
    #page-provider {
        .config {
            .board {
                &>h5 {
                    font-size: 18px;
                    line-height: 20px;
                }
            }

            .settings {
                .info {
                    .app-input {
                        max-width: 258px;
                    }
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    #page-provider {
        .btn-back-to {
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 8px;
        }

        .config {
            margin: 32px 0 0;
            padding: 16px 8px;

            .board {
                margin-top: 32px;
                
                &>h5 {
                    font-size: 16px;
                    line-height: 22px;
                }
            }

            .settings {
                .info {
                    flex-direction: column;
                    margin: 0 0 24px 0;

                    .app-input {
                        width: 100%;
                        max-width: 100%;
                        margin: 0 0 24px 0;

                        &:nth-child(2) {
                            margin: 0;
                        }
                    }

                    .logo-preview {
                        max-width: 100%;
                    }
                }
            }

            .features {
                .app-checkbox-group {
                    .options {
                        justify-content: space-between;

                        .app-checkbox {
                            width: 35%;
                            min-width: 120px;
                            margin: 24px 0 0;
                        }
                    }
                }
            }

            &>.btn {
                margin-top: 24px;
            }
        }
    }
}
</style>